<template>
  <CRow>
    <CCol col="12">
      <CCard no-header :accentColor="isFormDirty ? (isFormValid ? 'success' : 'danger') : ''">
        <CCardBody>
          <h3>{{ form.id ? $t('Edit share') + ` id: ${form.id}` : $t('Create share') }}</h3>
          <CForm autocomplete="off" @keydown="clearErrors($event.target.name || 'test')">
            <ACard>
              <AMultiSelect :horizontal="{ input: 'col-sm-6' }" name="building_id" label="Building" v-model="form.building_id" :options="lists.buildings" :isValid="isValid('building_id')" :errors="getErrors('building_id')" />
              <AMultiSelect :horizontal="{ input: 'col-sm-6' }" name="imputation_id" label="Imputation" v-model="form.imputation_id" :options="lists.imputations" :isValid="isValid('imputation_id')" :errors="getErrors('imputation_id')" />
              <AMultiSelect :horizontal="{ input: 'col-sm-6' }" name="module_id" label="Module" v-model="form.module_id" :options="lists.modules" :isValid="isValid('module_id')" :errors="getErrors('module_id')" />
              <AInput :horizontal="{ input: 'col-sm-3' }" type="number" label="Share" name="index_cop" v-model="form.index_cop" :isValid="isValid('index_cop')" :errors="getErrors('index_cop')" />
            </ACard>
          </CForm>
          <CCardFooter>
            <CButton class="mr-2" :color="form.id ? 'info' : 'primary'" adisabled="!isFormValid" @click="submit">{{ $t(form.id ? 'Save' : 'Create') }}</CButton>
            <CButton color="secondary" @click="goBack">{{ $t('Back') }}</CButton>
          </CCardFooter>
        </CCardBody>
      </CCard>
    </CCol>

    <!-- // TODO: Debug view... create a global component -->
    <CCol v-if="DEBUG">
      <pre>{{ form }}</pre>
      <div class="summary text-red" v-if="$v.form.$error">
        Form has errors
        <pre>{{ showErrors }}</pre>
      </div>
    </CCol>
  </CRow>
</template>

<script>
import formMixin from '@/app/views/_mixins/form-mixin'

import { DEBUG } from '@/config/config'

export default {
  name: 'ShareForm',
  mixins: [formMixin],

  data: () => {
    return {
      DEBUG: DEBUG, // TODO: Global or in Helper

      // DATA
      form: { id: 0 },

      // Helpers
      lists: {},
      alerts: [],
      objects: {},
      validators: {}
    }
  },
  created() {
    this.form.id = this.$route.params.id || 0
    this.getData()
  },
  methods: {
    goBack() {
      this.$router.go(-1)
    },
    getData() {
      const self = this
      self.$http
        .get('admin/building_shares' + (self.form.id ? `/${self.form.id}/edit` : '/create'))
        .then(response => {
          self.parseData(response.data)
        })
        .catch(error => {
          console.error(error)
          self.showAlert(`There was an error.`, 'danger')
          //self.goBack() // TODO: Alert
          //self.$router.push({ path: 'login' })
        })
    },

    // Parse Extra and Related data
    parseData(data) {
      this.form = data.building_share ? data.building_share : { id: 0 }
      this.is_principal = !!this.form.is_principal
      this.lists = data._lists || {}

      this.parseView()
      this.setObjects()
      this.parsedBuildings()

      this.validators = {
        main: data._validation || {}
        //extra: data._extra_validation || {}
      }

      if (_.isEmpty(this.$v.$form)) this.parseValidator(data._validation, data._messages, true)

      //if (this.form.id) this.forcedSteps()
    },

    // Helpers
    setObjects() {
      this.objects.building_types = {}
      for (const building_type of this.lists.building_types || []) {
        this.objects.building_types[building_type.id] = building_type
      }
      this.objects.property_units = {}
      for (const property_unit of this.lists.property_units || []) {
        this.objects.property_units[property_unit.id] = property_unit
      }
    },

    // Parsing

    parseView() {
      const options = [
        { click: this.submit, class: 'mr-2 btn-' + (this.form.id ? 'info' : 'primary'), content: this.$t(this.form.id ? 'Save' : 'Create') }, //disabled: this.isFormValid },
        { click: this.goBack, class: 'btn-secondary', content: this.$t('Back') }
      ]
      this.$store.dispatch('setTopActions', { [(this.form.id ? 'Edit' : 'Create') + 'Share']: options })
    },
    parsedBuildings() {
      this.lists.buildings.forEach(item => {
        item.name = `(${this.objects.property_units[item.property_unit_id].name}) ${this.objects.building_types[item.building_type_id].name} - ${item.code}`
      })
    },

    // SUBMIT

    submit() {
      this.$v.form.$touch()
      if (this.$v.form.$error) return

      const self = this
      self.$http[self.form.id ? 'put' : 'post']('admin/building_shares', self.form) // TODO: on service ?
        .then(response => {
          self.$router.replace(`/admin/property/shares/${response.data.building_share.id}/edit`).catch(() => {})
          self.showAlert(`Share ${self.form.id ? 'saved' : 'created'}.`)
          self.parseData(response.data)
        })
        .catch(error => {
          // TODO: move to form helper ?
          //if (error && error.status === 422) {
          if (error.response && error.response.status === 422) {
            console.log('parseInputErrors')
            self.setErrors(error.response.data.errors || {})
          }
          if (error.response?.data?.message == 'The given data was invalid.') {
            for (const key in error.response.data.errors) {
              if (error.response.data.errors[key]) {
                self.message += error.response.data.errors[key][0] + '  '
              }
            }
          } else {
            console.error(error)
            self.showAlert(`There was an error ${self.form.id ? 'saving' : 'creating'} the share.`, 'danger')
            //self.goBack() // TODO: login ?
          }
        })
    }
  }
}
</script>
